import has from 'lodash-es/has'

import Rest from '../Rest'

export default class DataAccessService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/data_access'
  model:any = null

  static build(vs: any = null, model:any =null) {
    const newRoleService = new this(this.resource, null, vs, null)
    newRoleService.model = { ...model}
    return newRoleService
  }

  verifyModel(paramModel)
  {
    if (!(paramModel || this.model)) {
      throw new Error('DataAccessService calls require either the model instance property or a model param.');
    } else {
      const model = {...this.model, ...paramModel}
      if ((!has(model, 'type') || !model.type) && (!has(model, 'id') || !model.id)) {
        throw new Error('DataAccessService model should be an object containing a non-null id and type property');
      }
      return model
    }

  }

  modelRemoveRole(roleId: Number, model:any = null) {
    model = this.verifyModel(model)
    if (model !== null) {
      return this.delete(`/${model.type}/${model.id}/role/${roleId}`)
    }
  }

  modelAddRole(roleId: Number, model:any = null) {
    model = this.verifyModel(model)
    if (model !== null) {
      return this.post(`/${model.type}/${model.id}/role/${roleId}`)
    }
  }

  grid(path: string = '', data: any) {
    if (path !== '') {
      return this.get(`${path}`, data)
    }
    return this.get('/grid', data)
  }
}
